/* eslint-disable jsx-a11y/label-has-associated-control */
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EXIT_PATH_NAME_OPTIONS, GEOJSON_PROPERTY_TYPES, paddockNamedPropertyTypes, } from '@halter-corp/geojson-feature-validator';
import { ComplexPaddockTypeEnum, PaddockTypeEnum } from '@halter-corp/topography-service-client';
import _includes from 'lodash/includes';
import _values from 'lodash/values';
import Modal from 'react-modal/lib';
import { WaypointEditor } from './waypoint-editor';
export var EditFeatureModal = function (_a) {
    var _b;
    var isOpen = _a.isOpen, modalStyles = _a.modalStyles, typeOptions = _a.typeOptions, enclosingPaddock = _a.enclosingPaddock, paddockNames = _a.paddockNames, allDestinations = _a.allDestinations, racewayDestinationType = _a.racewayDestinationType, raceWaypointKeys = _a.raceWaypointKeys, handleFeatureModalCancel = _a.handleFeatureModalCancel, handleFeatureModalSave = _a.handleFeatureModalSave, 
    // In props
    featureType = _a.featureType, featureName = _a.featureName, featureId = _a.featureId, featureKey = _a.featureKey, featurePaddockName = _a.featurePaddockName, featurePaddockType = _a.featurePaddockType, featureShiftHubPrimaryLinkId = _a.featureShiftHubPrimaryLinkId, featureComplexPaddockType = _a.featureComplexPaddockType, isShiftDestination = _a.isShiftDestination, entryForDestinationId = _a.entryForDestinationId, exitForDestinationId = _a.exitForDestinationId, featureTypeSelect = _a.featureTypeSelect, createNewDestination = _a.createNewDestination, featureDirectLinkWaypointKeys = _a.featureDirectLinkWaypointKeys, isFeaturePropsInvalid = _a.isFeaturePropsInvalid, 
    // Out props
    handleFeatureModalEdit = _a.handleFeatureModalEdit, handleFeatureModalTypeChange = _a.handleFeatureModalTypeChange, handleFeatureModalNameChange = _a.handleFeatureModalNameChange, featureNameChange = _a.featureNameChange, handleFeatureModalPaddockNameChange = _a.handleFeatureModalPaddockNameChange, setCreateNewDestination = _a.setCreateNewDestination, isShiftDestinationChange = _a.isShiftDestinationChange, entryForDestinationIdChange = _a.entryForDestinationIdChange, exitForDestinationIdChange = _a.exitForDestinationIdChange, featureDirectLinkWaypointKeysChange = _a.featureDirectLinkWaypointKeysChange, handleFeatureModalComplexPaddockTypeChange = _a.handleFeatureModalComplexPaddockTypeChange, handleFeatureModalPaddockTypeChange = _a.handleFeatureModalPaddockTypeChange, handleFeatureModalIsComplexPaddockChange = _a.handleFeatureModalIsComplexPaddockChange, handleFeatureRacewayDestinationTypeChange = _a.handleFeatureRacewayDestinationTypeChange, handleFeatureModalShiftHubShiftDestinationChange = _a.handleFeatureModalShiftHubShiftDestinationChange;
    var mapComplexPaddockTypeEnumToText = function (complexPaddockType) {
        if (complexPaddockType === ComplexPaddockTypeEnum.HIGH_COMPLEXITY_PADDOCK) {
            return 'High complexity paddock';
        }
        else
            return 'Low complexity paddock';
    };
    return (React.createElement(Modal, { isOpen: isOpen, contentLabel: "Feature Modal", style: modalStyles, shouldCloseOnOverlayClick: false },
        React.createElement("strong", { style: { fontSize: '18px' } }, featureType ? "Edit ".concat(featureType) : "Create ".concat(typeOptions)),
        _includes([GEOJSON_PROPERTY_TYPES.PADDOCK, GEOJSON_PROPERTY_TYPES.EXIT_PATH, GEOJSON_PROPERTY_TYPES.EXIT_POINT], featureType) && (React.createElement("button", { type: "button", onClick: handleFeatureModalEdit, className: "custom-white-button", style: { marginLeft: '112px' } }, "Filter")),
        featureType === GEOJSON_PROPERTY_TYPES.RACE_WAYPOINT &&
            (isShiftDestination || entryForDestinationId != null) && React.createElement("strong", null, " (".concat(featureName, ")")),
        React.createElement("br", null),
        React.createElement("p", { style: { fontSize: '12px', width: '300px', marginTop: '16px' } }, featureType === 'exit-point'
            ? 'Note: For internal gateways please create another gate and assign it to the adjoining paddock'
            : ''),
        React.createElement("form", { id: featureId },
            React.createElement("div", { className: "row form-group" },
                React.createElement("label", { className: "col-4", htmlFor: "feature-type" }, "Type"),
                React.createElement("div", { className: "col-8" },
                    React.createElement("select", { id: "feature-type", name: "feature-type", value: featureType, onChange: handleFeatureModalTypeChange, className: "custom-select" },
                        React.createElement("option", { key: "default", value: "" }, "Select feature"), featureTypeSelect === null || featureTypeSelect === void 0 ? void 0 :
                        featureTypeSelect.map(function (featureTypeOption) {
                            var name = featureTypeOption.name, disabled = featureTypeOption.disabled;
                            return (React.createElement("option", { key: name, value: name, disabled: disabled }, name));
                        })))),
            _includes([
                GEOJSON_PROPERTY_TYPES.PADDOCK,
                GEOJSON_PROPERTY_TYPES.EXIT_POINT,
                GEOJSON_PROPERTY_TYPES.IRRIGATED_AREA,
                GEOJSON_PROPERTY_TYPES.NON_HARVESTABLE_AREA,
                GEOJSON_PROPERTY_TYPES.TREE,
                GEOJSON_PROPERTY_TYPES.WATERWAY,
                GEOJSON_PROPERTY_TYPES.PERMANENT_STRUCTURE,
                GEOJSON_PROPERTY_TYPES.BUSH_BLOCK,
                GEOJSON_PROPERTY_TYPES.POND,
                GEOJSON_PROPERTY_TYPES.TRANSMISSION_TOWER,
            ], featureType) && (React.createElement("div", { className: "row form-group" },
                React.createElement("label", { className: "col-4", htmlFor: "feature-name" }, featureType === GEOJSON_PROPERTY_TYPES.EXIT_POINT ? 'Gate #' : 'Name'),
                React.createElement("div", { className: "col-8" },
                    React.createElement("input", { id: "feature-name", value: featureName, onChange: handleFeatureModalNameChange, className: "form-control", type: "text" })))),
            _includes([GEOJSON_PROPERTY_TYPES.EXIT_PATH], featureType) && (React.createElement("span", null,
                React.createElement("br", null),
                React.createElement("label", { htmlFor: "feature-name" },
                    React.createElement("span", { style: { paddingRight: '32px' } }, "Destination"),
                    React.createElement("br", null),
                    createNewDestination ? (React.createElement("div", { className: "custom-input", style: { display: 'block', width: '298px', padding: '6px 0px 6px 12px' } },
                        React.createElement("input", { className: "destination-input", id: "other-exit-path-option", style: { border: 'none', width: '256px' }, value: featureName, placeholder: "Type here", onChange: handleFeatureModalNameChange }),
                        featureName.length > 0 && (React.createElement("button", { className: "clear-path-input", onClick: function () { return setCreateNewDestination(false); }, type: "button" },
                            React.createElement(FontAwesomeIcon, { icon: "times" }))))) : (React.createElement("select", { id: "feature-name", value: featureName, onChange: handleFeatureModalNameChange, style: { minWidth: '200px' }, className: "custom-select" },
                        React.createElement("option", { key: "default", value: "" }, "select ..."),
                        _values(EXIT_PATH_NAME_OPTIONS).map(function (name) { return (React.createElement("option", { key: name, value: name }, name)); }),
                        React.createElement("option", { disabled: true }),
                        React.createElement("option", { value: "" }, "Create new ...")))),
                React.createElement("br", null))),
            _includes([GEOJSON_PROPERTY_TYPES.SHIFT_HUB], featureType) && (React.createElement("div", null,
                React.createElement("div", { className: "row" },
                    React.createElement("label", { className: "col-4", htmlFor: "shiftDestinationForShiftHub" }, "Shift Destination"),
                    React.createElement("div", { className: "col-8" },
                        React.createElement("select", { id: "shift-destination", value: featureShiftHubPrimaryLinkId, onChange: handleFeatureModalShiftHubShiftDestinationChange, style: { minWidth: '200px' }, className: "custom-select" },
                            React.createElement("option", { key: "default", value: "" }, "select..."),
                            allDestinations.map(function (destination) { return (React.createElement("option", { key: destination.properties.id, value: destination.properties.id }, destination.properties.name)); })))))),
            _includes(['race-waypoint'], featureType) && (React.createElement(WaypointEditor, { featureEntryForDestinationId: entryForDestinationId, featureEntryForDestinationIdChange: entryForDestinationIdChange, featureIsShiftDestination: isShiftDestination, featureIsShiftDestinationChange: isShiftDestinationChange, featureExitForDestinationId: exitForDestinationId, featureExitForDestinationIdChange: exitForDestinationIdChange, featureDirectLinkWaypointKeys: featureDirectLinkWaypointKeys, featureDirectLinkWaypointKeysChange: featureDirectLinkWaypointKeysChange, featureName: featureName, featureNameChange: featureNameChange, featureKey: featureKey, allDestinations: allDestinations, racewayDestinationType: racewayDestinationType, isNewDestinationName: createNewDestination, isNewDestinationNameChange: function (newValue) { return setCreateNewDestination(newValue); }, raceWaypointKeys: raceWaypointKeys, racewayDestinationTypeChange: handleFeatureRacewayDestinationTypeChange })),
            _includes(__spreadArray(__spreadArray([], paddockNamedPropertyTypes, true), [GEOJSON_PROPERTY_TYPES.TROUGH], false), featureType) && (React.createElement("div", { className: "row" },
                React.createElement("label", { className: "col-4", htmlFor: "paddock-name" }, "Paddock"),
                React.createElement("div", { className: "col-8" },
                    React.createElement("select", { id: "feature-group", value: featurePaddockName, onChange: handleFeatureModalPaddockNameChange, className: "custom-select" },
                        React.createElement("option", { key: "default", value: "" }, "select ..."),
                        ((_b = featureTypeSelect[0]) === null || _b === void 0 ? void 0 : _b.name) === GEOJSON_PROPERTY_TYPES.TROUGH
                            ? paddockNames
                                .filter(function (item) { return item === enclosingPaddock; })
                                .sort()
                                .map(function (paddockName) { return (React.createElement("option", { key: paddockName, value: paddockName }, paddockName)); })
                            : paddockNames
                                .reverse()
                                .sort()
                                .map(function (paddockName) { return (React.createElement("option", { key: paddockName, value: paddockName }, paddockName)); }))))),
            _includes([GEOJSON_PROPERTY_TYPES.PADDOCK], featureType) && (React.createElement("div", null,
                React.createElement("div", { className: "row" },
                    React.createElement("label", { className: "col-4", htmlFor: "paddock-type" }, "Paddock Type"),
                    React.createElement("div", { className: "col-8" },
                        React.createElement("select", { id: "paddock-type", value: featurePaddockType !== null && featurePaddockType !== void 0 ? featurePaddockType : PaddockTypeEnum.NORMAL, onChange: handleFeatureModalPaddockTypeChange, style: { minWidth: '200px' }, className: "custom-select" }, _values(PaddockTypeEnum).map(function (name) { return (React.createElement("option", { key: name, value: name }, name)); })))),
                React.createElement("label", { htmlFor: "is-complex" },
                    React.createElement("span", { style: { paddingRight: '12px' } }, "Is complex paddock"),
                    React.createElement("input", { type: "checkbox", checked: featureComplexPaddockType != null, onChange: handleFeatureModalIsComplexPaddockChange })),
                React.createElement("br", null),
                featureComplexPaddockType && (React.createElement("div", { className: "row" },
                    React.createElement("label", { className: "col-4", htmlFor: "complex-type" }, "Complex Type"),
                    React.createElement("div", { className: "col-8" },
                        React.createElement("select", { id: "complex-paddock-type", value: featureComplexPaddockType !== null && featureComplexPaddockType !== void 0 ? featureComplexPaddockType : ComplexPaddockTypeEnum.LOW_COMPLEXITY_PADDOCK, onChange: handleFeatureModalComplexPaddockTypeChange, style: { minWidth: '200px' }, className: "custom-select" }, _values(ComplexPaddockTypeEnum).map(function (name) { return (React.createElement("option", { key: name, value: name }, mapComplexPaddockTypeEnumToText(name))); }))))))),
            React.createElement("div", { className: "d-flex flex-row justify-content-between mt-4" },
                React.createElement("button", { type: "button", onClick: handleFeatureModalCancel, className: "btn btn-outline-secondary" }, "Cancel"),
                React.createElement("button", { type: "button", onClick: handleFeatureModalSave, disabled: isOpen && isFeaturePropsInvalid, className: "btn btn-success" }, "Done")))));
};
