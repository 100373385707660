/* eslint-disable jsx-a11y/label-has-associated-control */
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EXIT_PATH_NAME_OPTIONS } from '@halter-corp/geojson-feature-validator';
import { RaceWaypointDestinationTypeEnum } from '@halter-corp/topography-service-client';
import _values from 'lodash/values';
var ENTRY_NAMES = ['North', 'East', 'South', 'West', 'Northeast', 'Southeast', 'Southwest', 'Northwest'];
var WaypointType;
(function (WaypointType) {
    WaypointType["NORMAL"] = "NORMAL";
    WaypointType["SHIFT_DESTINATION"] = "SHIFT_DESTINATION";
    WaypointType["DESTINATION_ENTRY"] = "DESTINATION_ENTRY";
    WaypointType["DESTINATION_EXIT"] = "DESTINATION_EXIT";
})(WaypointType || (WaypointType = {}));
export function WaypointEditor(_a) {
    var featureKey = _a.featureKey, featureName = _a.featureName, featureNameChange = _a.featureNameChange, featureDirectLinkWaypointKeys = _a.featureDirectLinkWaypointKeys, featureDirectLinkWaypointKeysChange = _a.featureDirectLinkWaypointKeysChange, featureIsShiftDestination = _a.featureIsShiftDestination, featureIsShiftDestinationChange = _a.featureIsShiftDestinationChange, featureEntryForDestinationId = _a.featureEntryForDestinationId, featureEntryForDestinationIdChange = _a.featureEntryForDestinationIdChange, featureExitForDestinationId = _a.featureExitForDestinationId, featureExitForDestinationIdChange = _a.featureExitForDestinationIdChange, isNewDestinationName = _a.isNewDestinationName, isNewDestinationNameChange = _a.isNewDestinationNameChange, allDestinations = _a.allDestinations, racewayDestinationType = _a.racewayDestinationType, raceWaypointKeys = _a.raceWaypointKeys, racewayDestinationTypeChange = _a.racewayDestinationTypeChange;
    var handleWaypointTypeChange = function (e, newType) {
        var _a, _b, _c, _d, _e, _f;
        e.preventDefault();
        // Normal
        if (featureName != null && newType === WaypointType.NORMAL) {
            featureNameChange(null);
        }
        // featureIsShiftDestination
        if (featureIsShiftDestination && newType !== WaypointType.SHIFT_DESTINATION) {
            featureIsShiftDestinationChange(false);
        }
        else if (newType === WaypointType.SHIFT_DESTINATION) {
            featureIsShiftDestinationChange(true);
            if (!_values(EXIT_PATH_NAME_OPTIONS).includes(featureName)) {
                featureNameChange(EXIT_PATH_NAME_OPTIONS.MILKING_SHED);
            }
        }
        // featureEntryForDestinationId
        if (featureEntryForDestinationId != null && newType !== WaypointType.DESTINATION_ENTRY) {
            featureEntryForDestinationIdChange(null);
        }
        else if (newType === WaypointType.DESTINATION_ENTRY) {
            featureEntryForDestinationIdChange((_c = (_b = (_a = allDestinations[0]) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : null);
            if (!ENTRY_NAMES.includes(featureName)) {
                featureNameChange(ENTRY_NAMES[0]);
            }
        }
        // featureExitForDestinationId
        if (featureExitForDestinationId != null && newType !== WaypointType.DESTINATION_EXIT) {
            featureExitForDestinationIdChange(null);
        }
        else if (newType === WaypointType.DESTINATION_EXIT) {
            featureExitForDestinationIdChange((_f = (_e = (_d = allDestinations[0]) === null || _d === void 0 ? void 0 : _d.properties) === null || _e === void 0 ? void 0 : _e.id) !== null && _f !== void 0 ? _f : null);
            featureNameChange(null);
        }
    };
    var handleFeatureDestinationTypeChange = function (e) {
        var newDestinationType = e.currentTarget.value;
        if (newDestinationType === '') {
            racewayDestinationTypeChange(undefined);
        }
        else {
            racewayDestinationTypeChange(newDestinationType);
        }
    };
    var handleFeatureModalNameChange = function (e) {
        var newFeatureName = e.currentTarget.value;
        if (newFeatureName === '') {
            isNewDestinationNameChange(true);
        }
        featureNameChange(newFeatureName);
    };
    var handleFeatureDirectWaypointLinksChange = function (key, e) {
        if (e.currentTarget.checked) {
            featureDirectLinkWaypointKeysChange(__spreadArray(__spreadArray([], featureDirectLinkWaypointKeys, true), [key], false));
        }
        else {
            featureDirectLinkWaypointKeysChange(featureDirectLinkWaypointKeys.filter(function (k) { return k !== key; }));
        }
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "row" },
            React.createElement("label", { className: "col-form-label col-4", htmlFor: "race-waypoint-key-input" }, "Key"),
            React.createElement("div", { className: "col-8" },
                React.createElement("input", { className: "form-control", id: "race-waypoint-key-input", value: featureKey, placeholder: "Type here", disabled: true }))),
        React.createElement("div", { className: "col-form-label" }, "Links to"),
        React.createElement("div", { style: {
                width: '500px',
                height: '200px',
                overflowY: 'auto',
                border: '1px solid gray',
            } },
            React.createElement("div", { className: "row" }, raceWaypointKeys
                .filter(function (key) { return key !== featureKey; })
                .map(function (key) { return (React.createElement("div", { className: "col-3", key: key },
                React.createElement("div", { className: "form-check" },
                    React.createElement("input", { type: "checkbox", id: "raceway-".concat(key), checked: featureDirectLinkWaypointKeys.includes(key), onChange: function (e) { return handleFeatureDirectWaypointLinksChange(key, e); }, className: "form-check-input" }),
                    React.createElement("label", { htmlFor: "raceway-".concat(key), className: "form-check-label" }, key)))); }))),
        React.createElement("div", { className: "col-form-label mt-3" }, "Waypoint type"),
        React.createElement("ul", { className: "nav nav-pills mb-2" },
            React.createElement("li", { className: "nav-item" },
                React.createElement("a", { href: "#NORMAL", className: "nav-link ".concat(!featureIsShiftDestination && featureEntryForDestinationId == null && featureExitForDestinationId == null ? 'active' : ''), onClick: function (e) { return handleWaypointTypeChange(e, WaypointType.NORMAL); } }, "Normal")),
            React.createElement("li", { className: "nav-item" },
                React.createElement("a", { href: "#SHIFT_DESTINATION", className: "nav-link ".concat(featureIsShiftDestination ? 'active' : ''), onClick: function (e) { return handleWaypointTypeChange(e, WaypointType.SHIFT_DESTINATION); } }, "Destination")),
            React.createElement("li", { className: "nav-item" },
                React.createElement("a", { href: "#DESTINATION_ENTRY", className: "nav-link ".concat(featureEntryForDestinationId != null ? 'active' : ''), onClick: function (e) { return handleWaypointTypeChange(e, WaypointType.DESTINATION_ENTRY); } }, "Destination Entry")),
            React.createElement("li", { className: "nav-item" },
                React.createElement("a", { href: "#DESTINATION_EXIT", className: "nav-link ".concat(featureExitForDestinationId != null ? 'active' : ''), onClick: function (e) { return handleWaypointTypeChange(e, WaypointType.DESTINATION_EXIT); } }, "Destination Exit"))),
        React.createElement("div", { className: "tab-content" },
            React.createElement("div", { className: "tab-pane fade ".concat(featureIsShiftDestination ? 'show active' : '') },
                React.createElement("div", { className: "row form-group" },
                    React.createElement("div", { className: "col-form-label col-4" }, "Name"),
                    React.createElement("div", { className: "col-8" }, isNewDestinationName ? (React.createElement("div", { className: "input-group" },
                        React.createElement("input", { className: "form-control", id: "race-waypoint-destination", value: featureName, placeholder: "Type here", onChange: handleFeatureModalNameChange }),
                        featureName.length > 0 && (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                        React.createElement("div", { className: "input-group-append", onClick: function () { return isNewDestinationNameChange(false); } },
                            React.createElement("div", { className: "input-group-text" },
                                React.createElement(FontAwesomeIcon, { icon: "times" })))))) : (React.createElement("select", { id: "feature-name", value: featureName, onChange: handleFeatureModalNameChange, className: "custom-select" },
                        React.createElement("option", { key: "default", value: "" }, "select ..."),
                        _values(EXIT_PATH_NAME_OPTIONS).map(function (name) { return (React.createElement("option", { key: name, value: name }, name)); }),
                        React.createElement("option", { key: "spacer", disabled: true }),
                        React.createElement("option", { key: "create-new", value: "" }, "Create new ..."))))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-form-label col-4" }, "Destination Type"),
                    React.createElement("div", { className: "col-8" },
                        React.createElement("select", { id: "feature-name", value: racewayDestinationType, onChange: handleFeatureDestinationTypeChange, className: "custom-select" },
                            React.createElement("option", { key: "default", value: "" }, "No destination type"),
                            Object.values(RaceWaypointDestinationTypeEnum).map(function (name) { return (React.createElement("option", { key: name, value: name }, name)); }))))),
            React.createElement("div", { className: "tab-pane fade ".concat(featureEntryForDestinationId != null ? 'show active' : '') },
                React.createElement("div", { className: "row form-group" },
                    React.createElement("label", { className: "col-form-label col-4", htmlFor: "featureEntryForDestinationId" }, "Destination"),
                    React.createElement("div", { className: "col-8" }, featureEntryForDestinationId != null && (React.createElement("select", { id: "featureEntryForDestinationId", className: "custom-select", onChange: function (e) { return featureEntryForDestinationIdChange(e.target.value); }, value: featureEntryForDestinationId }, allDestinations.map(function (destination) { return (React.createElement("option", { key: destination.properties.id, value: destination.properties.id }, "".concat(destination.properties.name, " (Key: ").concat(destination.properties.key, ")"))); }))))),
                React.createElement("div", { className: "row form-group" },
                    React.createElement("label", { className: "col-form-label col-4", htmlFor: "entryName" }, "Name"),
                    React.createElement("div", { className: "col-8" },
                        React.createElement("select", { className: "custom-select", value: featureName, onChange: handleFeatureModalNameChange, id: "entryName" }, ENTRY_NAMES.map(function (entryName) { return (React.createElement("option", { key: entryName, value: entryName }, entryName)); }))))),
            React.createElement("div", { className: "tab-pane fade ".concat(featureExitForDestinationId != null ? 'show active' : '') },
                React.createElement("div", { className: "row form-group" },
                    React.createElement("label", { className: "col-form-label col-4", htmlFor: "featureExitForDestinationId" }, "Destination"),
                    React.createElement("div", { className: "col-8" }, featureExitForDestinationId != null && (React.createElement("select", { id: "featureExitForDestinationId", className: "custom-select", onChange: function (e) { return featureExitForDestinationIdChange(e.target.value); }, value: featureExitForDestinationId }, allDestinations.map(function (destination) { return (React.createElement("option", { key: destination.properties.id, value: destination.properties.id }, "".concat(destination.properties.name, " (Key: ").concat(destination.properties.key, ")"))); })))))))));
}
